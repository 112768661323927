import React from 'react';

import logoGreen from '../../asset/images/logos/logo-green.svg'
import agility from '../../asset/images/footer/agility-x.png'
import credibility from '../../asset/images/footer/credibility-x.png'
import differently from '../../asset/images/footer/differently-x.png'

import arch from '../../asset/images/thin-arch.png'

import { Link, useNavigate } from 'react-router-dom';

import LightGallery from 'lightgallery/react';

import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

import bg from '../../asset/images/gallery.png'


import img1 from '../../asset/gallery/1.JPG'
import img2 from '../../asset/gallery/2.JPG';
import img3 from '../../asset/gallery/3.JPG';
import img4 from '../../asset/gallery/4.JPG';
import img5 from '../../asset/gallery/5.JPG';
import img6 from '../../asset/gallery/6.JPG';
import img7 from '../../asset/gallery/7.JPG';
import img8 from '../../asset/gallery/8.JPG';
import img9 from '../../asset/gallery/9.JPG';
import img10 from '../../asset/gallery/10.JPG';
import img11 from '../../asset/gallery/11.JPG';
import img12 from '../../asset/gallery/12.JPG';
import img13 from '../../asset/gallery/13.JPG';
import img14 from '../../asset/gallery/14.JPG';
import img15 from '../../asset/gallery/15.JPG';
import img16 from '../../asset/gallery/16.JPG';
import img17 from '../../asset/gallery/17.JPG';
import img18 from '../../asset/gallery/18.JPG';
import img19 from '../../asset/gallery/19.JPG';
import img20 from '../../asset/gallery/20.JPG';
import img21 from '../../asset/gallery/21.JPG';
import img22 from '../../asset/gallery/22.JPG';
import img23 from '../../asset/gallery/23.JPG';
import img24 from '../../asset/gallery/24.JPG';
import img25 from '../../asset/gallery/25.JPG';
import img26 from '../../asset/gallery/26.JPG';
import img27 from '../../asset/gallery/27.JPG';
import img28 from '../../asset/gallery/28.JPG';
import img29 from '../../asset/gallery/29.JPG';
import img30 from '../../asset/gallery/30.JPG';
import img31 from '../../asset/gallery/31.JPG';
import img32 from '../../asset/gallery/32.JPG';
import img33 from '../../asset/gallery/33.JPG';
import img34 from '../../asset/gallery/34.JPG';
import img35 from '../../asset/gallery/35.JPG';
import img36 from '../../asset/gallery/36.JPG';
import img37 from '../../asset/gallery/37.JPG';
import img38 from '../../asset/gallery/38.JPG';
import img39 from '../../asset/gallery/39.JPG';
import img40 from '../../asset/gallery/40.JPG';
import img41 from '../../asset/gallery/41.JPG';
import img42 from '../../asset/gallery/42.JPG';
import img43 from '../../asset/gallery/43.JPG';
import img44 from '../../asset/gallery/44.JPG';
import img45 from '../../asset/gallery/45.JPG';
import img46 from '../../asset/gallery/46.JPG';
import img47 from '../../asset/gallery/47.JPG';
import img48 from '../../asset/gallery/48.JPG';
import img49 from '../../asset/gallery/49.JPG';
import img50 from '../../asset/gallery/50.JPG';
import img51 from '../../asset/gallery/51.JPG';
import img52 from '../../asset/gallery/52.JPG';
import img53 from '../../asset/gallery/53.jpeg';
import img54 from '../../asset/gallery/54.jpeg';
import img55 from '../../asset/gallery/55.jpeg';
import img56 from '../../asset/gallery/56.jpeg';
import img57 from '../../asset/gallery/57.jpeg';
import img58 from '../../asset/gallery/58.jpeg';





const PhotoLibrary = () => {

  document.title = 'Photo Library | SNB Capital 2024';

  const navigate = useNavigate()

  return (
    <div className='wrapper photo-library' style={{ position: 'relative' }}>

      <div className='container-fluid main-content px-0'>
        <div className='header'>
          <div className='d-flex justify-content-end d-md-block logo-div'>
            <img onClick={() => navigate('/')} src={logoGreen} className='pl-logo cursor-pointer' alt="logo" />
          </div>
        </div>
        <div className='p-relative'>

          <LightGallery
            
            speed={500}
            plugins={[lgZoom]}
            mobileSettings={
              { download: true }
            }
          >
            <a href={img1} id='lg-link'></a>
            <a href={img2} id='lg-link'></a>
            <a href={img3} id='lg-link'></a>
            <a href={img4} id='lg-link'></a>
            <a href={img5} id='lg-link'></a>
            <a href={img6} id='lg-link'></a>
            <a href={img7} id='lg-link'></a>
            <a href={img8} id='lg-link'></a>
            <a href={img9} id='lg-link'></a>
            <a href={img10} id='lg-link'></a>
            <a href={img11} id='lg-link'></a>
            <a href={img12} id='lg-link'></a>
            <a href={img13} id='lg-link'></a>
            <a href={img14} id='lg-link'></a>
            <a href={img15} id='lg-link'></a>
            <a href={img16} id='lg-link'></a>
            <a href={img17} id='lg-link'></a>
            <a href={img18} id='lg-link'></a>
            <a href={img19} id='lg-link'></a>
            <a href={img20} id='lg-link'></a>
            <a href={img21} id='lg-link'></a>
            <a href={img22} id='lg-link'></a>
            <a href={img23} id='lg-link'></a>
            <a href={img24} id='lg-link'></a>
            <a href={img25} id='lg-link'></a>
            <a href={img26} id='lg-link'></a>
            <a href={img27} id='lg-link'></a>
            <a href={img28} id='lg-link'></a>
            <a href={img29} id='lg-link'></a>
            <a href={img30} id='lg-link'></a>
            <a href={img31} id='lg-link'></a>
            <a href={img32} id='lg-link'></a>
            <a href={img33} id='lg-link'></a>
            <a href={img34} id='lg-link'></a>
            <a href={img35} id='lg-link'></a>
            <a href={img36} id='lg-link'></a>
            <a href={img37} id='lg-link'></a>
            <a href={img38} id='lg-link'></a>
            <a href={img39} id='lg-link'></a>
            <a href={img40} id='lg-link'></a>
            <a href={img41} id='lg-link'></a>
            <a href={img42} id='lg-link'></a>
            <a href={img43} id='lg-link'></a>
            <a href={img44} id='lg-link'></a>
            <a href={img45} id='lg-link'></a>
            <a href={img46} id='lg-link'></a>
            <a href={img47} id='lg-link'></a>
            <a href={img48} id='lg-link'></a>
            <a href={img49} id='lg-link'></a>
            <a href={img50} id='lg-link'></a>
            <a href={img51} id='lg-link'></a>
            <a href={img52} id='lg-link'></a>
            <a href={img53} id='lg-link'></a>
            <a href={img54} id='lg-link'></a>
            <a href={img55} id='lg-link'></a>
            <a href={img56} id='lg-link'></a>
            <a href={img57} id='lg-link'></a>
            <a href={img58} id='lg-link'></a>


          </LightGallery>
        </div>
      
          <img onClick={() => document.getElementById('lg-link').click()} className='lightgallery-img' src={bg} />

      </div>

      <div className='home-footer'>
        <div className='container-fluid p-relative'>

          <img className='mb-3 arch' src={arch} alt="arch" />

          <Link to='/' className='me-3 d-block d-md-inline mb-3 mb-md-0'>
            <button className='btn-white'>
              Back
            </button>
          </Link>

          <span className='pl-footer-icons'>
            <img className='credibility' src={credibility} alt="" />
            <img className='agility' src={agility} alt="" />
            <img className='differently' src={differently} alt="" />
          </span>

        </div>
      </div>
    </div>
  );
};

export default PhotoLibrary;
