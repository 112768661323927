import React from 'react';

import logoGreen from '../../asset/images/logos/logo-green.svg'
import agility from '../../asset/images/footer/agility-x.png'
import credibility from '../../asset/images/footer/credibility-x.png'
import differently from '../../asset/images/footer/differently-x.png'

import { Link, useNavigate } from 'react-router-dom';

import { useQuery } from 'react-query';
import axios from 'axios';

import IP from '../../sections/variables'

import { formatTime } from '../../utils/formatTime'

import { slideInDown, fadeIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';


const Agenda = () => {

  document.title = 'Agenda | SNB Capital 2024';

  const navigate = useNavigate()
 
  const styles = {
    slideDown: {
      animationDuration: '1s',
      animationName: Radium.keyframes(slideInDown, 'slideInDown'),
    },
    fade: {
      animationDuration: '.5s',
      animationName: Radium.keyframes(fadeIn, 'fadeIn'),
    },
  };





  const URL = `${window.location.protocol}//${IP}/api/agenda/list-create`
  
  const {
    isLoading,
    isError,
    data,
    isSuccess,
    refetch
} = useQuery(`agenda`, async () => axios.get(URL))

  const agenda = data?.data ?? []



  return (
    <div className='wrapper agenda'>

      <div className='container-fluid main-content px-0'>
        <div className='d-flex justify-content-end'>
          <img onClick={() => navigate('/')} src={logoGreen} className='pl-logo cursor-pointer' alt="logo" />
        </div>
          <StyleRoot>
        <div class="table-div">
          <table>
            <tr>
              <th style={styles.fade}>Agenda item</th>
              <th style={styles.fade}>Start time</th>
              <th style={styles.fade}>End time</th>
              <th style={styles.fade}>Presenters</th>
            </tr>

            {agenda.length > 0 && agenda.map((agendaItem) => (
              agendaItem?.presenter ? 
              <tr style={styles.fade}>
                <td>{agendaItem?.title ?? 'N/A'}</td>
                <td>{agendaItem?.starting_time ? formatTime(agendaItem.starting_time) : 'N/A'}</td>
                <td>{agendaItem?.ending_time ? formatTime(agendaItem.ending_time) : 'N/A'}</td>
                <td>{agendaItem?.presenter ?? 'N/A'}</td>
              </tr>
              :
              <tr class="break-row">
                  <td colspan="4">{agendaItem?.title}</td>
            </tr>

            ))}
          </table>
        </div>
          </StyleRoot>
      </div>

      <div>
        <div className='container-fluid px-md-3 px-0'>

          <Link to='/' className='me-3 d-block d-md-inline mb-3 mb-md-0'>
            <button className='btn-transparent'>
              Back
            </button>
          </Link>

          <span className='pl-footer-icons'>
            <img className='credibility' src={credibility} alt="" />
            <img className='agility' src={agility} alt="" />
            <img className='differently' src={differently} alt="" />
          </span>

        </div>
      </div>
    </div>
  );
};

export default Agenda;