import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';

import { agendaActivitySchema } from '../../validations/validation-schemas';
import { useMutation } from 'react-query';

import useAxios from '../../api/useAxios';

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";


const AddActivityModal = ({
  isOpen,
  toggle,
  day,
  handleResponse
    }) => {

    const [modalStyles, setModalStyles] = useState({
      zIndex: '1',
    });

  const onSubmit = () => {

    console.log('in submit')

    const startingTime = new Date(`2000-01-01T${values.starting_time}`);
    const endingTime = new Date(`2000-01-01T${values.ending_time}`);

    if (endingTime < startingTime){
      toast.error('Starting time cannot be after ending time')
    }
    else{
      addActivityMutation.mutate(values)
    }
  }

  let api = useAxios();

  const addActivity = (payload) => {
    return api.post('/agenda/list-create', payload)
  }

  const addActivityMutation = useMutation(addActivity, {
    onSuccess: (res) => {
      resetForm()
      handleResponse('success', res)
    },
    onError: (res) => {
      handleResponse('error', res)
    }
  })

  const modules = {
    toolbar: [
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['clean'],                  
      [
        { indent: '-1' },
        { indent: '+1' },
      ],
    ],
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
    initialValues: {
      title: "",
      presenter: "",
      starting_time: "",
      ending_time: "",
    },
    validationSchema: agendaActivitySchema,
    onSubmit,
    enableReinitialize: true,
  })

  useEffect(() => {
      console.log('errors', errors)
      console.log('values', values)
  }, [errors, values])

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-lg'>
      <ModalHeader toggle={toggle}>
        <h4 className='text-primary mb-0'>Add New Activity</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
      <ModalBody>
          <div className='row'>
     
            <div className='col-md-6 col-12'>
              <Label className="form-label text-start mt-3 mb-1">Activity</Label>
              <Input
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                className={errors.title && touched.title ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon green-border col-6"}
                id="activityTitle"
                placeholder="Enter Activity Title"
                name="title"
              />
              {
                errors.title && touched.title && <div className="invalid-feedback">{errors.title}</div>
              }
            </div>

            <div className='col-md-6 col-12'>
              <Label className="form-label text-start mt-3 mb-1">Presenter</Label>
              <Input
                value={values.presenter}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                className={errors.presenter && touched.presenter ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon green-border col-6"}
                id="firstNameInput"
                placeholder="Enter Presenter's Name"
                name="presenter"
              />
              {
                errors.presenter && touched.presenter && <div className="invalid-feedback">{errors.presenter}</div>
              }
            </div>


            <div className='col-md-6 col-12'>
              <Label className="form-label text-start mt-3 mb-1">Starting Time</Label>
              <Input
                value={values.starting_time}
                onChange={handleChange}
                onBlur={handleBlur}
                type="time"
                className={errors.starting_time && touched.starting_time ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon green-border col-6"}
                id="starting_timeInput"
                name="starting_time"
              />
              {
                errors.starting_time && touched.starting_time && <div className="invalid-feedback">{errors.starting_time}</div>
              }
            </div>
            
            <div className='col-md-6 col-12'>
              <Label className="form-label text-start mt-3 mb-1">Ending Time</Label>
              <Input
                value={values.ending_time}
                onChange={handleChange}
                onBlur={handleBlur}
                type="time"
                className={errors.ending_time && touched.ending_time ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon green-border col-6"}
                id="ending_timeInput"
                name="ending_time"
              />
              {
                errors.ending_time && touched.ending_time && <div className="invalid-feedback">{errors.ending_time}</div>
              }
            </div>

          </div>  

      </ModalBody>
      <ModalFooter>
        {
          addActivityMutation.isLoading ? 
          <p>Processing...</p>
          :
          <>
            <button onClick={toggle} type="button" className="btn admin-grey-btn">
          Close
        </button>
        <button type="submit" className="btn admin-green-btn">
          Save
        </button>
</>
        }
      </ModalFooter>
        </form>
    </Modal>
  );
};

export default AddActivityModal;
