import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import logo1 from '../../asset/images/logos/logo-white.svg';
import { LoginForm } from '../../sections/auth/login';

import { useStore } from '../../App'
import { useNavigate } from 'react-router-dom';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  height : '100vh',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(0),
}));

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const [imageWidth, setImageWidth] = useState(window.innerWidth >= 768 ? '300px' : '500px');

  useEffect(() => {
    const updateImageWidth = () => {
      setImageWidth(window.innerWidth >= 768 ? '300px' : '500px');
    };

    window.addEventListener('resize', updateImageWidth);

    return () => {
      window.removeEventListener('resize', updateImageWidth);
    };
  }, []);

  // const navigate = useNavigate()

  // // const userStatus = useStore((state) => state.userStatus)

  // // if (userStatus){
  // //   navigate('/admin/agenda-listing')
  // // }

  return (
    <div className='admin-login-wrapper'>

        <Container maxWidth="sm">
          <StyledContent>
            <div>
              <img
                src={logo1}
                className="mx-auto"
                alt="Not Found"
                style={{ width: imageWidth}}
              />
            </div>
            <Typography className='text-white text-bold text-center' variant="h4" gutterBottom style={{ marginTop: '35px' }}>
              Sign in to SNB Capital Board Off-Site Meeting
            </Typography>

            <LoginForm />
            
          </StyledContent>
        </Container>
   
    </div>
  );
}
