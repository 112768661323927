import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import Page404 from './pages/Page404';

import Home from './pages/public/home';
import Agenda from './pages/public/agenda';
import PhotoLibrary from './pages/public/photo-library';

import AgendaListing from './pages/admin/agenda-listing';
import LoginPage from './pages/admin/login';

import { useStore } from './App';

const AdminRoute = ({ children }) => {
  const userStatus = useStore((state) => state.userStatus);
  
  return userStatus === true ? children : <Navigate to="/admin/sign-in" />;
}



const RedirectAdmin = ({ children }) => {
  
  const userStatus = useStore((state) => state.userStatus);
  
  return(

    userStatus === true ? <Navigate to="/admin/agenda-listing" /> : children
    )

}

export default function Router() {
  const routes = useRoutes([
    {
      element: <DashboardLayout />,
      children: [
        { path: 'admin/agenda-listing', element: <AdminRoute><AgendaListing /></AdminRoute> },
      ],
    },
   
    {
      element: <SimpleLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace />}
      ],
    },
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/agenda',
      element: <Agenda />,
    },
    {
      path: 'admin/sign-in',
      element: <RedirectAdmin><LoginPage /></RedirectAdmin>,
    },
    {
      path: '/photo-library',
      element: <PhotoLibrary />,
    },
  ]);

  return routes;
}
