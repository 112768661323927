import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import 'bootstrap/dist/css/bootstrap.css';
import { BsPencilFill, BsFillPlusCircleFill, BsTrashFill } from 'react-icons/bs';


import AddActivityModal from '../../components/common/add-activity-modal';
import EditActivityModal from '../../components/common/edit-activity';
import DeleteActivityModal from '../../components/common/delete-activity'

import {formatDate} from '../../utils/formatTime'

import { toast } from 'react-toastify';

import { useQuery } from 'react-query';
import useAxios from '../../api/useAxios';

const AgendaListing = () => {
  
  document.title = 'Agenda | SNB Offsite';

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [modalData, setModalData] = useState(false)
  const [deleteModalData, setDeleteModalData] = useState(false)


  let api = useAxios()

  const {
    isLoading,
    isError,
    data,
    isSuccess,
    refetch
} = useQuery(`agenda`, async () => api.get('/agenda/list-create'))


  const agenda = data?.data ?? []

  console.log(agenda)


  const setEditModalData = (values) => {
      
      setModalData({
        'id': values?.id,
        'title': values?.title,
        'presenter': values?.presenter,
        'starting_time': values.starting_time,
        'ending_time': values.ending_time
      })
  }

  const toggleEditModal = () => {
    if (isEditModalOpen){
      setModalData(false); 
    }
    setEditModalOpen(!isEditModalOpen)
  }

  const toggleDeleteModal = () => {
      setDeleteModalOpen(!isDeleteModalOpen)
  }

  useEffect(() => {
    if(modalData){
      toggleEditModal()      
    }

  },[modalData])


  useEffect(() => {
    if (deleteModalData){
      setDeleteModalOpen(true)
    }

  }, [deleteModalData])


  const agendaColumns = useMemo(
    () => [
      {
        header: '#',
        size: 30,
        Cell: ({ row }) => <span>{parseInt(row.id, 10) + 1}.</span>
      },
      {
        accessorKey: 'title',
        header: 'Activity',
        Cell: ({ row }) => (
          <p><div dangerouslySetInnerHTML={{ __html: row.original.title }} /></p>
        )
      },
      {
        accessorKey: 'presenter',
        header: 'Presenter',
      },
      {
        accessorKey: 'time',
        header: 'Time',
        size: 100,
      },

      {
        header: 'Edit',
        size: 100,
        Cell: ({ row }) => (
         <BsPencilFill
            onClick={() => setEditModalData(row.original)}
            style={{ cursor: 'pointer' }}
          />
        )
      },
      {
        header: 'Delete',
        size: 100,
        Cell: ({ row }) => (
         <BsTrashFill
            onClick={() => setDeleteModalData({'title': row.original.title, 'id': row.original.id})}
            style={{ cursor: 'pointer' }}
          />
        )
      },
    ],
    []
  );


  const handleResponse = (status, res) => {

    let unexpectedValue = true

    if (status === 'success'){
        toast.success('Done')
        unexpectedValue = false
        
        setAddModalOpen(false)
        setEditModalOpen(false)
        setDeleteModalOpen(false)
        setModalData(false)
        setDeleteModalData(false)
        
        refetch()

      }
    if (status === 'error'){

      if (res?.response?.data?.error){
          toast.error(res?.response?.data?.error)
          unexpectedValue = false
        }
        else{
          unexpectedValue = true          
      }
    }

    if (unexpectedValue){
      toast.error('Something went wrong')
    }
  }


  const handleDeleteResponse = (status, res) => {

    if (status === 'success'){
        toast.success('Activity Deleted')
        setDeleteModalOpen(false)
        refetch()
      }
    if (status === 'error'){
      toast.error('Something went wrong')
    }
  }
  

  return (
    <div className='admin-content'>
      <div
        className="container-fluid breadcrumb-color mt-4 mb-3"  id='breadCrumbStyling'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb d-block">
            <li
              className="breadcrumb-item text-white text-center active fs-4 py-3 px-2 d-flex align-items-center justify-content-between"
              aria-current="page"
              id='breadCrumbTitleStyling'>
              <span>Agenda</span>
              <span>
              <BsFillPlusCircleFill
                onClick={() => setAddModalOpen(true)}
                className='cursor-pointer'
                />
              </span>
            </li>
          </ol>
        </nav>
      </div>


        <div className="container-fluid p-4" id='formStyling'>
          <MaterialReactTable
            columns={agendaColumns}
            data={agenda}
            muiTablePaperProps={{
              elevation: 10,
            }}
            state={{isLoading: isLoading}}
          />
        </div>
      

        <AddActivityModal
          isOpen={isAddModalOpen}
          toggle={() => {setAddModalOpen(false)}}
          handleResponse={handleResponse}
          />


        <EditActivityModal
          isOpen={isEditModalOpen}
          toggle={toggleEditModal}
          data={modalData}
          handleResponse={handleResponse}
        />


        <DeleteActivityModal
          isOpen={isDeleteModalOpen}
          toggle={toggleDeleteModal}
          handleResponse={handleDeleteResponse}
          data={deleteModalData}
        />

    </div>
  );
          }

export default AgendaListing;
