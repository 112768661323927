import React, { useState, useEffect } from 'react';
import logoWhite from '../../asset/images/logos/logo-white.svg';
import { Link } from 'react-router-dom';
import { slideInDown, fadeIn } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

const Home = () => {
  document.title = 'SNB Capital 2024';
  
  
  const [firstAnimationComplete, setFirstAnimationComplete] = useState(false);

 const styles = {
    slideDown: {
      animationDuration: '1s',
      animationName: Radium.keyframes(slideInDown, 'slideInDown'),
    },
    fade: {
      animationDuration: '.5s',
      animationName: Radium.keyframes(fadeIn, 'fadeIn'),
    },
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFirstAnimationComplete(true);
    }, 300); // Adjust the timeout to match the first animation's duration

    return () => clearTimeout(timeoutId);
  }, []);


  return (
    <div className="wrapper home">
      <div className="container-fluid main-content">
        <div className="d-flex justify-content-end">
          <img src={logoWhite} className="logo" alt="logo" />
        </div>
        
        <StyleRoot>
        <div className="d-flex justify-content-center d-md-block" style={styles.fade}>
            <div className="title d-block">
              <h2 className="text-bold text-primary">SNB Capital Board Off-Site Meeting</h2>
              <h3 className="text-primary">Dubai 14-15 Nov 2023</h3>
            </div>
        </div>
      </StyleRoot>
      </div>

        <div className="container-fluid">
          <div className="home-footer">
         
          <div className='m-0 p-0'>
          <StyleRoot>
            {
              firstAnimationComplete &&
              <h2 style={styles.slideDown} className="text-white text-bold footer-text">Financial confidence in<br/> a new future</h2>
            }
          </StyleRoot>
            </div>

          <div className='m-0 p-0'>
          <Link to="/agenda">
            <button className="btn-transparent d-block mb-3">
              Agenda
            </button>
          </Link>
          <Link to="/photo-library">
            <button className="btn-transparent d-block">
              Photo Library
            </button>
          </Link>

        </div>
        </div>
      </div>
      
      {/* <div className="home-footer">
        <div className="container-fluid">
          <div className='m-0 p-0'>
          <StyleRoot>
            {
              firstAnimationComplete &&
              <h2 style={styles.slideDown} className="text-white text-bold footer-text">Financial confidence in<br/> a new future</h2>
            }
          </StyleRoot>
            </div>
          <div className='m-0 p-0'>
          <Link to="/agenda">
            <button className="btn-transparent d-block mb-3">
              Agenda
            </button>
          </Link>
          <Link to="/photo-library">
            <button className="btn-transparent d-block">
              Photo Library
            </button>
          </Link>

        </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
