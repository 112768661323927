import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { useMutation } from 'react-query';

import useAxios from '../../api/useAxios';


const DeleteActivityModal = ({
    isOpen,
    toggle,
    handleResponse,
    data,
    category
    }) => {

    const [modalStyles, setModalStyles] = useState({
      zIndex: '1',
    });


  let api = useAxios();

  const deleteActivity = (payload) => {
    return api.delete(`/agenda/edit-delete/${data.id}`)
  }

  const deleteActivityMutation = useMutation(deleteActivity, {
    onSuccess: (res) => {
      handleResponse('success', res)
    },
    onError: (res) => {
      handleResponse('error', res)
    }
  })

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-md'>
      <ModalHeader toggle={toggle}>
        <h4 className='text-primary mb-0'>Delete Activity</h4>
      </ModalHeader>
      <ModalBody>
          <div className='row py-3'>
              <h6>Are you sure you want to delete the activity: 
                <br/>
              <div dangerouslySetInnerHTML={{ __html: data?.title }} /></h6>
          </div>  

      </ModalBody>
      <ModalFooter>
        {
          deleteActivityMutation.isLoading ? 
          <p>Processing...</p>
          :
          <>
            <button onClick={toggle} type="button" className="btn admin-grey-btn">
          No
        </button>
        <button onClick={() => deleteActivityMutation.mutate()} type="submit" className="btn admin-green-btn">
          Yes
        </button>
  </>
        }
      </ModalFooter>
    </Modal>
  );
};

export default DeleteActivityModal;
