import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}



  export function formatTime(timeString) {
    // Split the time string by colons to get individual components
    const timeComponents = timeString.split(':');
  
    // Ensure that there are at least 2 components (hour and minute)
    if (timeComponents.length >= 2) {
      // Extract the hour and minute components
      const hour = parseInt(timeComponents[0]);
      const minute = parseInt(timeComponents[1]);
  
      // Format the hour and minute with leading zeros
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
  
      // Return the time without seconds as a formatted string
      return `${formattedHour}:${formattedMinute}`;
    } else {
      // Handle invalid input or missing components
      return 'Invalid time format';
    }
  }



export const formatDate = (dateString) => {
  const options = { month: 'long', day: 'numeric' };
  const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
  return formattedDate.replace(/\b0/g, ''); // Remove leading zeros from day
}
