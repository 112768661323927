import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';

import { agendaActivitySchema } from '../../validations/validation-schemas';
import { useMutation } from 'react-query';
import useAxios from '../../sections/useAxios';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EditActivityModal = ({
  isOpen,
  toggle,
  data,
  handleResponse
    }) => {

    const [modalStyles, setModalStyles] = useState({
      zIndex: '1',
    });

  const onSubmit = () => {
    const startingTime = new Date(`2000-01-01T${values.starting_time}`);
    const endingTime = new Date(`2000-01-01T${values.ending_time}`);

    if (endingTime < startingTime){
      toast.error('Starting time cannot be after ending time')
    }

    else{
      EditActivityMutation.mutate(values)
    }
  }


  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      title: data?.title,
      presenter: data?.presenter,
      starting_time: data?.starting_time,
      ending_time: data?.ending_time,
    },
    validationSchema: agendaActivitySchema,
    onSubmit,
    enableReinitialize: true,
  })

  let api = useAxios()

  const editActivty = (payload) => {

    return api.patch(`/agenda/edit-delete/${data.id}`, payload)

  } 

  const EditActivityMutation = useMutation(editActivty, {
    onSuccess: (res) => {
      handleResponse('success', res)
    },
    onError: (res) => {
      handleResponse('error', res)
    } 
  })

  const modules = {
    toolbar: [
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['clean'],                  
      [
        { indent: '-1' },
        { indent: '+1' },
      ],
    ],
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className='modal-lg'>
      <ModalHeader toggle={toggle}>
        <h4 className='text-primary mb-0'>Edit Activity Details</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
      <ModalBody>
          <div className='row'>
            <div className='col-md-6 col-12'>
              <Label className="form-label text-start mt-3 mb-1">Activity</Label>
              <Input
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                className={errors.title && touched.title ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon green-border col-6"}
                id="activityInput"
                placeholder="Enter Activity Title"
                name="title"
              />
              {
                errors.title && touched.title && <div className="invalid-feedback">{errors.title}</div>
              }
            </div>
            <div className='col-md-6 col-12'>
              <Label className="form-label text-start mt-3 mb-1">Presenter</Label>
              <Input
                value={values.presenter}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                className={errors.presenter && touched.presenter ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon green-border col-6"}
                id="presenterInput"
                placeholder="Enter Presenter's Name"
                name="presenter"
              />
              {
                errors.presenter && touched.presenter && <div className="invalid-feedback">{errors.presenter}</div>
              }
            </div>
            <div className='col-md-6 col-12'>
              <Label className="form-label text-start mt-3 mb-1">Starting Time*</Label>
              <Input
                value={values.starting_time}
                onChange={handleChange}
                onBlur={handleBlur}
                type="time"
                className={errors.starting_time && touched.starting_time ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon green-border col-6"}
                id="starting_timeInput"
                name="starting_time"
              />
              {
                errors.starting_time && touched.starting_time && <div className="invalid-feedback">{errors.starting_time}</div>
              }
            </div>
            <div className='col-md-6 col-12'>
              <Label className="form-label text-start mt-3 mb-1">Ending Time*</Label>
              <Input
                value={values.ending_time}
                onChange={handleChange}
                onBlur={handleBlur}
                type="time"
                className={errors.ending_time && touched.ending_time ? "form-control form-control-icon is-invalid col-6" : "form-control form-control-icon green-border col-6"}
                id="ending_timeInput"
                name="ending_time"
              />
              {
                errors.ending_time && touched.ending_time && <div className="invalid-feedback">{errors.ending_time}</div>
              }
            </div>

          </div>  

      </ModalBody>
      <ModalFooter>
            {
              EditActivityMutation.isLoading ? 
              <p>Processing...</p> :
              <>
              <button onClick={toggle} type="button" className="btn admin-grey-btn">
          Close
        </button>
        <button type="submit" className="btn admin-green-btn">
          Save
        </button></>
        }

      </ModalFooter>
        </form>
    </Modal>
  );
};

export default EditActivityModal;
