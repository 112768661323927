import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Spinner, Input, Label, Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';

import axios from 'axios';
import { useMutation } from 'react-query';
import { adminLoginSchema } from '../../../validations/validation-schemas';

import { useStore } from '../../../App';

import IP from '../../variables'

export default function LoginForm() {
  const navigate = useNavigate();


  const URL = `${window.location.protocol}//${IP}/api`

  const sendLogin = (payload) => {

    return axios.post(`${URL}/accounts/login`, payload)
  
  }

  const sendLoginMutation = useMutation(sendLogin, {
    onSuccess: (data) => {
      
      const tokens = data?.data?.tokens ?? false

      if (tokens) {
        useStore.setState({ userStatus: true })
        useStore.setState({ accessToken: tokens.access})
        useStore.setState({ refreshToken: tokens.refresh})
        
        navigate('/admin/agenda-listing');
      }


      else{
        toast.error('Something went wrong')
      }

    },
    onError: (data) => {
      if (data?.response?.status === 404){
        toast.error('Invaid Credentials')
      }
      else{
        toast.error('Something went wrong')
      }
    }
  })

  const onSubmit = () => {
    sendLoginMutation.mutate(values)
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    validationSchema: adminLoginSchema,
    onSubmit,
    enableReinitialize: true,
  })


  return (
    <form onSubmit={handleSubmit}>
      <div>
       
          <Label className="form-label text-start mt-3 mb-0 text-white text-bold">Username</Label>
          <Input
            value={values.username}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            className={errors.username && touched.username ? "form-control form-control-icon is-invalid login-field col-6" : "form-control form-control-icon login-field col-6"}
            id="usernameInput"
            placeholder="Enter Username"
            name="username"
          />
          {
            errors.username && touched.username && <div className="invalid-feedback">{errors.username}</div>
          }
       
          <Label className="form-label text-start mt-3 mb-0 text-white text-bold">Password</Label>
          <Input
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            className={errors.password && touched.password ? "form-control form-control-icon is-invalid login-field col-6" : "form-control form-control-icon login-field col-6"}
            id="firstNameInput"
            placeholder="Enter Password"
            name="password"
          />
          {
            errors.password && touched.password && <div className="invalid-feedback">{errors.password}</div>
          }

      </div>

     
      <div className='text-center'>
          <button 
              type="submit" 
              className="btn-login mt-3"
              disabled={sendLoginMutation.isLoading}>
              {sendLoginMutation.isLoading ? "Signing In" : 'Sign In'}
              {sendLoginMutation.isLoading &&  <Spinner size="sm" className="flex-shrink-0 ms-2"/>}
          </button>
      </div>
    </form>
  );
}
